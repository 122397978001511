import { RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  {
    path: '',
    redirectTo: environment.redirectHome,
    pathMatch: 'full',
  },

  {
    path: 'site',
    loadChildren: () => import('./layout/@pages/pages.module').then(m => m.PagesModule)
  },

  {
    path: 'auth',
    loadChildren: () => import('./layout/@auth/auth.module').then(m => m.AuthModule)
  },

  {
    path: 'notfound',
    loadChildren: () => import('./layout/@pages/notfound/notfound.module').then(m => m.NotfoundPageModule)
  },
];



@NgModule({
  imports: [RouterModule.forRoot(routes, {
    //initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
