import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocalstorageService } from './local.storage.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { finalize } from 'rxjs/operators';

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private ngxLoader: NgxUiLoaderService,
    private localStorageService: LocalstorageService) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.url.indexOf('getResume') <= 0) {
      try {


        setTimeout(() => {
          this.ngxLoader.startBackground();
        }, 50);


      } catch (error) { }

    }

    let currentUnit_value = this.localStorageService.getItem('currentUnit');
    let currentUnit = currentUnit_value ? JSON.parse(currentUnit_value) : null;

    const authReq = req.clone({
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
        'Authorization': 'my-auth-token',
        'houseId': environment.houseId,
        'appTargetId': environment.appTargetId,
        'businessUnitId': currentUnit ? currentUnit.id : ''
      })
    });

    return next.handle(authReq).pipe(finalize(() => {
      setTimeout(() => {
        try {
          this.ngxLoader.stopBackground();
        } catch (error) { }

        if(this.localStorageService.getItem(`stApp_${environment.appTargetId}`)) {
          try {
            this.ngxLoader.stop();
          } catch (error) { }
        }
      }, 100);




    }));;
  }

}

@NgModule({

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpsRequestInterceptor,
      multi: true,
    },

  ],
})

export class InterceptorModule { }
