import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FooterComponent } from './footer.component';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/shared.module';
import { RouterModule } from '@angular/router';
import { SubMenuComponent } from './sub-menu/sub-menu.component';
import { provideEnvironmentNgxMask } from 'ngx-mask';

@NgModule({
  declarations: [
    FooterComponent,
    SubMenuComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    PipesModule,

    FontAwesomeModule,
  ],
  exports: [
    //Export Components
    FooterComponent

  ],
  providers: [
    provideEnvironmentNgxMask(),

  ]
})
export class FooterSharedComponentsModule { }
