import { PipesModule, SharedModule } from '../shared.module';

import { AppLayoutComponent } from './app.layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DropdownButtonComponent } from './header/menu/sidebar-menu/dropdown-button/dropdown-button.component';
import { FooterSharedComponentsModule } from './footer/footer.shared.module';
import { HeaderComponent } from './header/header.component';
import { HorizontalMenuComponent } from './header/menu/horizontal-menu/horizontal-menu.component';
import { MenuComponent } from './header/menu/menu.component';
import { MenuItemComponent } from './header/menu/sidebar-menu/menu-item/menu-item.component';
import { NgModule } from '@angular/core';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { RouterModule } from '@angular/router';
import { SidebarMenuComponent } from './header/menu/sidebar-menu/sidebar-menu.component';
import { ngxUiLoaderConfig } from '../shared/config/nxUiConfig';

@NgModule({
  declarations: [
    AppLayoutComponent,

    //Menu
    SidebarMenuComponent,
    HorizontalMenuComponent,
    HeaderComponent,
    MenuComponent,
    DropdownButtonComponent,
    MenuItemComponent,

  ],

  imports: [
    BrowserAnimationsModule,
    RouterModule,
    SharedModule,
    PipesModule,
    FooterSharedComponentsModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
  ],
  exports: [AppLayoutComponent],
})
export class AppLayoutModule { }
